import axios, { AxiosResponse, ResponseType } from "axios";
import { INetwork } from "@/Interfaces/INetwork";
import { IApiHeaders } from "@/Interfaces/IApiHeaders";
import { authService, authServicePowerBi } from "@/auth/authSingleton";
import log from "loglevel";

export class NetworkService implements INetwork {
    private defaultHeaders: IApiHeaders[] = [];
    private noticescopes: string[] = [];
    private powerBi: boolean = false;
    private authService: any;
    private authServicePowerBi: any;
    constructor(
        public url: string,
        noticescopes?: string[],
        defaultHeaders?: IApiHeaders[],
        powerBi?: boolean
    ) {
        if (noticescopes !== undefined) {
            this.noticescopes = noticescopes;
        }
        if (defaultHeaders !== undefined) {
            this.defaultHeaders = defaultHeaders;
        }
        this.authService = authService;
        if (powerBi !== undefined) {
            this.powerBi = powerBi;
            if (powerBi === true) {
                this.authServicePowerBi = authServicePowerBi;
            }
        }
    }

    public async get(urlParams: string): Promise<AxiosResponse> {
        log.trace("get()\nurlParams", urlParams);

        const url: string = this.url + urlParams;

        const header = await this.getHeader();
        log.debug("get()\nurl", url, "\nheader", header);
        return new Promise((resolve, reject) => {
            const response = axios
                .get(url, { headers: header })
                .then((res: AxiosResponse<any>) => {
                    log.debug("get()\nres.data:", res.data);
                    resolve(res);
                }).catch((error: any) => {
                    reject(error);
                });
        });
    }

    public async post(data: any, urlParams: string, headers?: object, logging?: boolean, responseType?: ResponseType): Promise<AxiosResponse> {

        const url: string = this.url + urlParams;
        
        var header = await this.getHeader();

        if (headers) {
            log.debug("post()\nCombining headers:", JSON.stringify(headers));
            header = combineObjects(header, headers);
        }

		if (logging !== undefined && logging) {
            log.debug("post()\nurl:", url, "\nbody:", data, "\nheader", header);
        }
        return axios.post(url, data, { headers: header, responseType });
    }

    public async put(data: any, urlParams: string, headers?: object, logging?: boolean): Promise<AxiosResponse> {
        log.trace("put()\ndata", data);
      
        const url: string = this.url + urlParams;
        
        var header = await this.getHeader();

        if (headers) {
            log.debug("put()\nCombining headers:", JSON.stringify(headers));
            header = combineObjects(header, headers);
        }

		if (logging !== undefined && logging) {
            log.debug("put()\nurl:", url, "\nbody:", data, "\nheader", header);
        }

        return await axios.put(url, data, { headers: header });
    }

    public async delete(data: any, urlParams: string, headers?: object, logging?: boolean): Promise<AxiosResponse> {
        log.trace("delete()\ndata", data);
      
        const url: string = this.url + urlParams;
        
        var header = await this.getHeader();

        if (headers) {
            log.debug("delete()\nCombining headers:", JSON.stringify(headers));
            header = combineObjects(header, headers);
        }

		if (logging !== undefined && logging) {
            log.debug("delete()\nurl:", url, "\nbody:", data, "\nheader", header);
        }

        return await axios.delete(url, { data: data, headers: header });
    }

    private async getHeader(): Promise<object> {
        log.trace("getHeader()");
        
        const header: any = {};
        for (const headerData of this.defaultHeaders) {
            log.debug("getHeader()\n(defaultHeaders)\nheaderData:", headerData.value);
            header[headerData.name] = headerData.value;
        }
        if (this.noticescopes.length === 0) {
            const authValue = await this.getAuthHeaderValue();
			header["Authorization"] = "Bearer " + authValue;
        }
        if (this.powerBi) log.trace("getHeader()\nheader:", header);
        return new Promise<object>((resolve, reject) => {
            if (header != undefined) {
                resolve(header);
            } else {
                reject(header);
            }
        });
    }

    private async getAuthHeaderValue(): Promise<string> {
        log.trace("getAuthHeaderValue()");
        const token = await (this.powerBi ?
            this.authServicePowerBi.getApiToken(this.authService.getEmailAddress()) :
            this.authService.getApiToken());

        return new Promise<string>((resolve, reject) => {
            if (token != undefined) {
                resolve(token);
            } else {
                reject(token);
            }
        });
    }
}

function combineObjects(obj1: object, obj2: object) {
    const result = { ...obj1 };
  
    for (const key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        if (result.hasOwnProperty(key)) {
          throw new Error(`Duplicate key: ${key}`);
        } else {
          result[key] = obj2[key];
        }
      }
    }
  
    return result;
  }
