export const msalConfig = {
    auth: {
        clientId: process.env.VUE_APP_MSAL_CLIENT_ID_NORMAL!,
        redirectUri: process.env.VUE_APP_MSAL_REDIRECTURI_NORMAL!,
        authority: process.env.VUE_APP_MSAL_AUTHORITY_NORMAL!,
    },

    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },

    system: {
        tokenRenewalOffsetSeconds: 300,
    },
};

export const apiScope = process.env.VUE_APP_API_SCOPE_NORMAL!;

export const msalConfigPowerBi =  {
    auth: {
        clientId: process.env.VUE_APP_POWER_BI_API_CLIENT_ID!,
        redirectUri: process.env.VUE_APP_POWER_BI_REDIRECTURI!,
        authority: process.env.VUE_APP_POWER_BI_API_AUTHORITY!
    },

    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },

    system: {
        tokenRenewalOffsetSeconds: 300,
    },
};

export const apiScopePowerBi = process.env.VUE_APP_POWER_BI_API_NOTICE_SCOPES!;