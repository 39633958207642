import DateIgnoreTime from "@/types/DateIgnoreTime";
import IFinalSettlementPaymentItem from "./IFinalSettlementPaymentItem";
import { MarketSelection, isMarketSupported } from "@/services/helpers/Helper";

export default class FinalSettlementPaymentItem {
    constructor(raw: IFinalSettlementPaymentItem) {
        Object.assign(this, raw);
        if (!isMarketSupported(raw.market)) {
            console.error(`market ${raw.market} is unrecognised`, raw);
            this.market = 'GB';
        } else this.market = raw.market;
        this.dueDate = new DateIgnoreTime(raw.dueDate);
        this.issueDate = new DateIgnoreTime(raw.issueDate);
        this.balanceDateUpdated = new DateIgnoreTime(raw.balanceDateUpdated);
    }
    invoiceId!: string;
    amountTotal!: string;
    issueDate: DateIgnoreTime;
    dueDate: DateIgnoreTime;
    balance!: string;
    balanceDateUpdated: DateIgnoreTime;
    market: keyof typeof MarketSelection;
}