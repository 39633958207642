<template>
    <Popup
        :modelValue="!!selectedRow"
        @update:model-value="closeChild"
        title="Payment confirmation"
        hasClose
    >
        <template #contents v-if="selectedRow">
            <div class="grid-item">
                The remaining balance on this final settlement is
                <strong>{{ MarketCurrency[marketSelection]}} {{ MonetaryValueWithCommas(selectedRow.balance) }}</strong>
            </div>
            <form class="top-content" @submit.prevent="ConfirmTransfer">
                <span style="padding-bottom: 0.4rem"
                    >Enter a payment amount:
                </span>
                <span
                class="search-with-button"
                >
                    <CurrencyInput
                        id="amount"
                        ref=amount
                        v-model=paymentAmount
                        class="invalid-input"
                        :currency=MarketCurrency[marketSelection]
                        :max=selectedRow.balance
                        :min=0.01
                        :placeholder="'Amount'"
                        required
                    />
                    <button :disabled="working" class="right-button">Confirm</button>
                </span>
            </form>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, computed } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
import PaymentItem from "@/Interfaces/Payments/FinalSettlementPaymentItem";
import Popup from "./Popup.vue";
import CurrencyInput from "./CurrencyInput.vue";
import { confirmFinalSettlementPayment as confirmPayment } from "@/services/network";
import IConfirmFinalSettlementPaymentRequest from "@/Interfaces/Payments/IConfirmFinalSettlementPaymentRequest";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import { authService } from "@/auth/authSingleton";
import {
    MarketCurrency,
    MonetaryValueWithCommas,
    marketSelection,
} from "@/services/helpers/Helper";


const props = defineProps<{
    modelValue?: PaymentItem;
}>();
const selectedRow = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emits("update:modelValue", val);
    },
});
const working = ref(false);
const paymentAmount = ref<number>();
const amountTotalToSend = ref<number>();
const balanceToSend = ref<number>();
const emits = defineEmits<{
    (e: "update:modelValue", val?: PaymentItem);
    (e: "confirm");
}>();

function closeChild() {
    paymentAmount.value = undefined;
    selectedRow.value = undefined;
}

async function ConfirmTransfer() {
    if (working.value) return;
    working.value = true;
    const closeSpinner = useSpinnerPopup().show();
    try {
        if (!selectedRow.value)
            throw new ReferenceError(
                "selectedRow cannot be null when submitting the form"
            );
        if (!paymentAmount.value)
            throw new ReferenceError(
                "paymentAmount cannot be null when submitting the form"
            );
        if (isNaN(parseFloat(selectedRow.value.amountTotal)))
            throw new ReferenceError(
                "amountTotal must be a valid number when submitting the form"
            );
        if (isNaN(parseFloat(selectedRow.value.balance)))
            throw new ReferenceError(
                "balance must be a valid number when submitting the form"
            );
        amountTotalToSend.value = parseFloat(selectedRow.value.amountTotal);
        balanceToSend.value = parseFloat(selectedRow.value.balance);

        const payload : IConfirmFinalSettlementPaymentRequest = (
            {
                invoiceId: selectedRow.value.invoiceId,
                amountTotal: amountTotalToSend.value,
                issueDate: selectedRow.value.issueDate,
                dueDate: selectedRow.value.dueDate,
                balance: balanceToSend.value,
                userEmail: authService.getEmailAddress(),
                market: marketSelection.value,
                amountPaid: paymentAmount.value
            });
        const response = await confirmPayment.put(payload);
        useNotifierWithErrFormatter().success({
            msg: "Payment successfully registered.",
        });
        closeChild();
        emits("confirm");
    } catch (error) {
        useNotifierWithErrFormatter().error({
            name: `${__filename}/${ConfirmTransfer.name}`,
            errorType: "Confirming payment",
            error,
        });
    } finally {
        closeSpinner();
        working.value = false;
    }
}
</script>

<style scoped>
.grid-item {
    padding: 10px;
}
</style>
