<template>
    <header class="topbar">
        <div class="topbar-container">
            <a href="#" class="topbar-logo">
                <img src="../assets/logo.png" alt="Optio - Investment Partners"
            /></a>
            <div class="topbar-header">
                <h1 v-if="route.name == 'Car Purchase'">Car Purchases</h1>
                <h1 v-else-if="route.name !== 'Root'">{{ route.name }}</h1>
                <h1 v-else>Please navigate to your desired destination</h1>
            </div>
            <div v-if="authService.isLoggedIn()" class="topbar-username-and-button">
                <div class="topbar-username">
                    <!-- {{ authService.getUsersRole() }} -->
                    {{ username }}
                </div>
                <div>
                    <button @click="onLogout()" type="submit" class="topbar-button">
                        Logout
                    </button>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { authService } from "@/auth/authSingleton";
import { computed } from "@vue/reactivity";
import { useRoute } from "vue-router";
import { apiLoggerKey } from "@/types/ServiceKeys";
import router from "@/router";
import { RoutePaths } from "@/router/RouteDetails";

async function onLogout() {
    const name = onLogout.name.toString();
    const Message = "Logged out.";
    const Class = name;
    inject(apiLoggerKey)?.LogInformation(Message, Class);
    await authService.logout();
    router.push({ path: RoutePaths.Login });
}

const username = computed(() => {
    return authService.getUserName();
});

const route = useRoute();
</script>

<style>
.topbar {
    display: flex;
    position: relative;
    height: var(--topbar-height);
    min-height: var(--topbar-min-height);
    margin-left: var(--topbar-sidebar-content-spacing);
    margin-right: var(--topbar-sidebar-content-spacing);
    margin-bottom: calc(var(--margin-bottom-factor) * var(--topbar-sidebar-content-spacing));

    align-items: center;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.05), 5px 5px 10px rgba(0, 0, 0, 0.05);
    border: 0px;
    border-radius: var(--topbar-sidebar-content-radius);
    background-color: var(--background-color);
}
.topbar-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.topbar-logo {
    width: 200px;
    float: left;
    height: 100%;
}
.topbar-logo:hover {
    cursor: default;
}
.topbar-logo img {
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .1rem;
    padding-bottom: .1rem;
    height: 100%;
}
.logged-out-logo img {
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .1rem;
    padding-bottom: .1rem;
    height: 100%;
}
.topbar-header h1 {
    text-align: center;
    font-size: 1.4em;
}
.topbar-username-and-button {
  height: 80%;
  display: flex;
  flex-wrap: wrap; /* wrap the elements if they overflow */
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
}

.topbar-username {
  font-size: clamp(.75rem, .85vw, 1.75rem);
  padding: 3px;
  white-space: nowrap; /* prevent line breaks within the username */
  overflow: hidden; /* hide the overflow */
  text-overflow: ellipsis; /* show ellipsis if the text overflows */
}

.topbar-button {
  background-color: var(--primary-color-lighter);
  padding: 6px;
  color: blue;
  border: 0px;
  border-radius: var(--buttons-border-radius);
  font-weight: bold;
  margin-top: 2px;
  margin-left: 12px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: clamp(.65rem, .75vw, 1.65rem);
  white-space: nowrap; /* prevent line breaks within the button */
  overflow: hidden; /* hide the overflow */
  text-overflow: ellipsis; /* show ellipsis if the text overflows */
}
.topbar-button:hover {
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
}
.topbar-button:visited {
  color: inherit;
}
</style>