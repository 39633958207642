<template>
    <div class="app-component">
        <div v-if="showTopbar" class="topbar-component">
            <TopBar />
        </div>
        <div v-if="showSidebar" class="side-bar-content-container">
            <div class="sidebar-component">
                <SideBar class="side-bar" />
            </div>
            <div class="router-component">
                <router-view :key=key class="router-view" />
            </div>
        </div>
        <div v-else>
            <router-view :key=key />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, provide, onMounted, onBeforeUnmount, onUnmounted, nextTick, inject } from "vue";
import TopBar from "@/components/TopBar.vue";
import SideBar from "@/components/SideBar.vue";
// Services
import { authService } from "@/auth/authSingleton";
import { globalReloadKey, loggerKey } from "./types/ServiceKeys";
import { nonSidebarViews, nonTopbarViews } from "@/router/RouteDetails";
import router from "./router";

const log = inject(loggerKey);
console.log(`App.vue\nSetting log level to: ${process.env.VUE_APP_LOG_LEVEL}`);
if (process.env.VUE_APP_LOG_LEVEL?.toLowerCase() === "trace".toLowerCase()) {
    log?.setLevel("trace");
    console.log(`Log level set to: trace (${log?.getLevel()})`);
}
else if (process.env.VUE_APP_LOG_LEVEL?.toLowerCase() === "debug".toLowerCase()) {
    log?.setLevel("debug");
    console.log(`Log level set to: debug (${log?.getLevel()})`);
}
else if (process.env.VUE_APP_LOG_LEVEL?.toLowerCase() === "info".toLowerCase()) {
    log?.setLevel("info");
    console.log(`Log level set to: info (${log?.getLevel()})`);
}
else if (process.env.VUE_APP_LOG_LEVEL?.toLowerCase() === "warn".toLowerCase()) {
    log?.setLevel("warn");
    console.log(`Log level set to: warn (${log?.getLevel()})`);
}
else if (process.env.VUE_APP_LOG_LEVEL?.toLowerCase() === "error".toLowerCase()) {
    log?.setLevel("error");
    console.log(`Log level set to: error (${log?.getLevel()})`);
}
else {
    log?.setLevel("warn");
    console.log(`Log level set to: warn (${log?.getLevel()})`);
}
provide(globalReloadKey, refreshRouterView);

const screenWidth = ref(window.innerWidth);
const showComponent = computed(() => screenWidth.value >= 800);
const key = ref(0);

provide("showComponent", showComponent);

onMounted(async () => {
    window.addEventListener("resize", updateFontSize)
    window.addEventListener('resize', handleResize);
    await ChangeSidebar();
    await ChangeTopbar();
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
});

onUnmounted(() => window.removeEventListener("resize", updateFontSize));
function updateFontSize() {
    var root = document.getElementsByTagName('html')[0];
    root.style.fontSize = `${getZoom()}vw`;
}

function handleResize() {
    screenWidth.value = window.innerWidth;
}

function getZoom(): number {
    var result = window.devicePixelRatio;
    log?.trace(`zoom level: ${result * 100}%`);

    return result;
}

const showSidebar = ref(false);
const showTopbar = ref(false);

watch(
    () => router.currentRoute.value.path,
    async () => {
        // log?.debug("path", route.path);
        await ChangeSidebar();
        await ChangeTopbar();
    }
);
async function isShowingSideBar() {
    return await authService.isLoggedIn() && !nonSidebarViews.some((substr) => router.currentRoute.value.path.startsWith(substr));
}
async function ChangeSidebar() {
    showSidebar.value = await isShowingSideBar();
}
async function isShowingTopBar() {
    return await authService.isLoggedIn() && !nonTopbarViews.some((substr) => router.currentRoute.value.path.startsWith(substr));
}
async function ChangeTopbar() {
    showTopbar.value = await isShowingTopBar();
}
function refreshRouterView() {
    log?.info('refreshing router view');
    key.value = key.value + 1;
    if (key.value > 1000) key.value = 0;
    nextTick(()=>{
        log?.info('pushing original router path', router.currentRoute.value.path);
        router.push({path:router.currentRoute.value.path, query:router.currentRoute.value.query});
    });
}
</script>

<style lang="scss">
@import "@/assets/styles/buttons.scss";
@import "@/assets/styles/layout.scss";

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    font-size: clamp(.75rem, .75vw, 1.75rem);
}

html {
    font-size: 1.25rem !important;
}

* {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

:root {
    /* ===== Colors ===== */
    --body-color: #e4e9f7;
    --sidebar-color: #fff;
    --primary-color: #695cfe;
    --primary-color-light: #f6f5ff;
    --primary-color-lighter: #dbd7ff;
    --toggle-color: #ddd;
    --text-color: #707070;
    --background-color: #f8f8f8;
    --primary-color-dark: #44475c;
    --light-grey: #e4e4e4;
    --red-background-color: rgba(255, 0, 0, 0.2);
    --orange-background-color: rgba(255, 179, 0, 0.2);
    --btn-text-color: blue;
    --text-invert-color: white;

    --theme-link-color: #3685c5;
    --theme-link-color--hover: #204e74;

    --btn-text-color: blue;
    --text-inverted-color: white;

    /* ====== Transition ====== */
    --tran-01: all 0.1s ease;
    --tran-02: all 0.2s ease;
    --tran-03: all 0.3s ease;
    --tran-04: all 0.4s ease;
    --tran-05: all 0.5s ease;
    --tran-2: all 2s ease;

    /* ===== Content ===== */
    --default-body-margin: 8px;
    --topbar-sidebar-content-spacing: 6px;
    --content-width: 100%;
    --topbar-sidebar-content-radius: 1rem;
    --buttons-border-radius: .5rem;
    --dropdown-radius: .5rem;
    --table-button-radius: 50vh;
    --sidebar-items-radius: .5rem;
    --topbar-height: 8vh;
    --topbar-min-height: 50px;
    --margin-top-factor: 1;
    --margin-bottom-factor: 3;
    --margin-sides-factor: 1;
    --border-width: 2px;
    --sidebar-width: 11em;
}

body {
    background-color: var(--background-color);
}

.body {
    transition: var(--tran-03);
}

button {
    font-size: inherit;
}

button:visited {
    color: inherit;
}

.router-link:visited {
    color: inherit;
}

.app-component {
    display: flex;
    flex-direction: column;
    height: calc(100vh - (2 * var(--default-body-margin)));
}

.side-bar-content-container {
    display: flex;
    /* height: 100%; */
    /* overflow: hidden; */
    margin-left: calc(var(--margin-sides-factor) * var(--topbar-sidebar-content-spacing));
    margin-right: calc(var(--margin-sides-factor) * var(--topbar-sidebar-content-spacing));
    /* margin-bottom: calc(var(--margin-bottom-factor) * var(--topbar-sidebar-content-spacing)); */
    padding-bottom: calc(var(--margin-bottom-factor) * var(--topbar-sidebar-content-spacing));
}

.sidebar-component {
    position: relative;
    transition: var(--tran-03);

    /* height:80vh; */
    min-height: 100%;
    display: flex;
    flex-direction: column;
    /* Make sure it's a column layout. */
    /* flex-grow: 1; */
}

.router-component {
    position: relative;
    width: calc(100% - calc(3 * var(--topbar-sidebar-content-spacing)) - var(--sidebar-width));
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.05), 5px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: var(--topbar-sidebar-content-radius);

    display: flex;
    flex-direction: column;
    /* height: 80vh; */
    /* height: 100%; */

    height: calc(100vh - var(--default-body-margin) - var(--topbar-height) - calc(var(--margin-bottom-factor) * var(--topbar-sidebar-content-spacing)) - (4 * var(--topbar-sidebar-content-spacing)));
    /* overflow-y: scroll; */
    flex-grow: 1;
}

.router-view {
    position: relative;
    display: flex;
    height: 100%;
    /* width: 100%; */
    flex-grow: 1;
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: var(--content-width);
    padding-left: 2em;
    padding-right: 2em;
}

.incident-nav-button {
    margin: 5px;
}

.incident-nav-button:visited {
    color: inherit;
}

.incident-nav-button:disabled {
    color: var(--primary-color-dark);
}

.incident-nav-button:disabled:hover {
    background-color: var(--primary-color-lighter);
    cursor: not-allowed;
}

.center-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
    color: var(--primary-color-dark);
    font-weight: bold;
}

.center-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
}

.center-buttons button {
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
}

/* .center-buttons button:disabled {
    color: var(--primary-color-dark);
    background-color: var(--light-grey);
} */
.center-buttons button:hover {
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
}

.center-button-selected {
    /* display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px; */
    background-color: var(--primary-color) !important;
    color: white !important;
}

.center-buttons button:visited {
    color: inherit;
}

.center-buttons button:disabled:hover {
    background-color: var(--primary-color-lighter);
    cursor: not-allowed;
}

.center-dropdown {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: var(--primary-color-dark);
    margin-right: 1vw;
    margin-left: 1vw;
    margin-top: 10px;
    margin-bottom: 10px;
}

.top-content {
    /* Divs in this container should appear stacked */
    width: var(--content-width);
}

.center-dropdown-table {
    display: inline-table;
    width: var(--content-width);
    font-weight: bold;
    color: var(--primary-color-dark);
    margin-top: 10px;
    margin-bottom: 10px;
    border-collapse: collapse;
}

.center-dropdown-table td {
    text-align: center;
    width: 33.3%;
    padding: 0px;
}

.center-dropdown-table select {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border: 2px solid var(--primary-color-dark);
    border-radius: var(--dropdown-radius);
    /* font-size: medium; */
    font-weight: bold;
    margin-top: 5px;
    color: var(--primary-color-dark);
    text-align: center;
    width: 10rem;
}

.center-dropdown select {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: 2px solid var(--primary-color-dark);
    border-radius: var(--dropdown-radius);
    font-size: large;
    font-weight: bold;
    margin-right: 15px;
    margin-left: 15px;
    color: var(--primary-color-dark);
    text-align: center;
    width: 200px;
}

.center-buttons button {
    border-width: 0px;
}

.center-buttons button:disabled {
    background-color: var(--light-grey);
    color: #b6b6b6;
}

.error {
    background-color: var(--red-background-color);
    padding-left: 4em;
    padding-right: 4em;
    padding-top: 1em;
    padding-bottom: 1em;
    color: red;
}

.warning {
    background-color: var(--orange-background-color);
    padding-left: 4em;
    padding-right: 4em;
    padding-top: 1em;
    padding-bottom: 1em;
    color: orange;
}

.close-error button {
    float: right;
    border-width: 0px;
    background-color: transparent;
    color: inherit;
}

.close-error button:hover {
    font-weight: bold;
    cursor: pointer;
}

.close-error button:visited {
    color: inherit;
}

.error-header {
    display: inline-block;
    font-size: 1.5rem;
    padding-bottom: 0.3em;
}

.desc-error {
    position: relative;
    margin: auto;
    width: var(--content-width);
    padding-top: 1em;
}

.desc {
    background-color: var(--primary-color-lighter);
    padding-left: 4em;
    padding-right: 4em;
    padding-top: 1em;
    padding-bottom: 1em;
    color: black;
}

.desc-header {
    display: inline-block;
    font-size: 1.5rem;
    padding-bottom: 0.3em;
}

.embedded-dropdown {
    display: flex;
    height: 2.3rem;
    margin-top: 0 !important;
    border-radius: 0 !important;
    font-weight: normal !important;
    padding: 0 !important;
}

.date-picker button {
    height: 25px;
    border-radius: 0px;
    border: var(--buttons-border-width) solid var(--primary-color-dark);
    border-color: var(--primary-color-dark);
    background-color: var(--primary-color-lighter);
    font-weight: bold;
    color: blue;
}

.date-picker input:not([type=checkbox]):not([type=radio]) {
    padding-left: 40px;
    font-size: 1em;
    min-width: 7.5rem;
}

.dp__theme_light {
    /*General*/
    --dp-font-family: sans-serif;

    /*Sizing*/
    --dp-button-height: 25px;
    /*Size for buttons in overlays*/
    --dp-month-year-row-height: 25px;
    /*Height of the month-year select row*/
    --dp-month-year-row-button-size: 25px;
    /*Specific height for the next/previous buttons*/
    --dp-button-icon-height: 20px;
    /*Icon sizing in buttons*/
    --dp-cell-size: 25px;
    /*Width and height of calendar cell*/
    --dp-cell-padding: 5px;
    /*Padding in the cell*/
    --dp-common-padding: 10px;
    /*Common padding used*/
    --dp-input-icon-padding: 25px;
    /*Padding on the left side of the input if icon is present*/
    --dp-input-padding: 6px 30px 6px 12px;
    /*Padding in the input*/
    --dp-menu-min-width: 260px;
    /*Adjust the min width of the menu*/
    --dp-action-buttons-padding: 2px 5px;
    /*Adjust padding for the action buttons in action row*/
    --dp-row-maring: 5px 0;
    /*Adjust the spacing between rows in the calendar*/
    --dp-calendar-header-cell-padding: 0.5rem;
    /*Adjust padding in calendar header cells*/
    --dp-two-calendars-spacing: 10px;
    /*Space between multiple calendars*/
    --dp-overlay-col-padding: 3px;
    /*Padding in the overlay column*/
    --dp-time-inc-dec-button-size: 20px;
    /*Sizing for arrow buttons in the time picker*/
    --dp-menu-padding: 6px 8px;
    /*Menu padding*/

    /*Font sizes*/
    --dp-font-size: 0.8rem;
    /*Default font-size*/
    --dp-preview-font-size: 0.5rem;
    /*Font size of the date preview in the action row*/
    --dp-time-font-size: 0.7rem;
    /*Font size in the time picker*/
}

.table-container {
    position: relative;
    width: inherit;
    overflow-x: auto;
    /* overflow-x: scroll; */
    /* overflow-y: hidden; */
    height: 100%;
    padding-bottom: calc(3 * var(--topbar-sidebar-content-spacing));
    /* margin-bottom: calc(1 * var(--topbar-sidebar-content-spacing)); */
    /* flex-grow: 1; */
}

.table-button:hover {
    text-decoration: underline;
}

.table-button:visited {
    color: inherit;
}

.loading {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    /* Vertical centering */
    justify-content: center;
    /* Horizontal centering */
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: var(--primary-color-lighter) var(--primary-color-dark) transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    z-index: 100;
}

.loader::after,
.loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent var(--primary-color) var(--primary-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
}

.loader::before {
    width: 32px;
    height: 32px;
    border-color: var(--primary-color-lighter) var(--primary-color-dark) transparent transparent;
    animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotationBack {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

td {
    vertical-align: top;
    text-align: left;
    padding: 8px;
}

th {
    text-align: left;
}

.table-button {
    border-radius: var(--table-button-radius);
    border-width: 1px;
    border-color: blue;
    color: blue;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.table-button:hover {
    background-color: var(--primary-color-lighter);
    cursor: pointer;
}

.table-button:disabled {
    border-color: #9b9b9b;
    color: #9b9b9b;
}

.table-button:disabled:hover {
    background-color: var(--primary-color-lighter);
    cursor: not-allowed;
}

.table-button:visited {
    color: inherit;
}

.logo {
    padding: 20px;
    height: 100px;
}

.login-button {
    background-color: black;
    color: white;
    padding: 32px 64px;
    border: none;
    border-radius: var(--buttons-border-radius);
    cursor: pointer;
    font-size: 16px;
}

.fake-link {
    color: blue;
    text-decoration: underline;
    border: none;
    background-color: transparent;
}

.fake-link:hover {
    cursor: pointer;
    font-style: italic;
}

.fake-link:disabled {
    color: var(--primary-color-lighter);
}

.success {
    color: lightgreen;
    font-weight: bold;
}

.medium {
    color: orange;
    font-weight: bold;
}

.failure {
    color: red;
    font-weight: bold;
}

.no-underline {
    text-decoration: none;
}

.hidden {
    visibility: hidden;
}
</style>