import * as Msal from "@azure/msal-browser";
import { msalConfig, apiScope, msalConfigPowerBi, apiScopePowerBi } from "@/config/msalConfig";
import log from "loglevel";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export default class AuthService {
    constructor(powerBi) {
        this.powerBi = powerBi;

        this.loginRequest = {
            scopes: [!powerBi ? apiScope : apiScopePowerBi],
            prompt:  "select_account",
        };
        this.accessTokenRequest = {
            scopes: [!powerBi ? apiScope : apiScopePowerBi],
        };

        this.app = !powerBi ? new Msal.PublicClientApplication(msalConfig) : new Msal.PublicClientApplication(msalConfigPowerBi);
        this.authInProgress = false;
        this.initializeApp();
    }

    async initializeApp() {
        try {
            await this.app.initialize();
            log.debug("AuthService\ninitializeApp()\nMSAL instance initialized successfully.");
        } catch (error) {
            log.error("AuthService\ninitializeApp()\nInitialization error:", error);
        }
    }

    async login() {
        log.debug("AuthService\nlogin()");
        try {
            const loginResponse = await this.app.loginPopup(this.loginRequest);
            log.trace("AuthService\nlogin()\nloginResponse:", loginResponse);

            this.app.setActiveAccount(loginResponse.account);
            const accessTokenResponse = await this.app.acquireTokenSilent(this.accessTokenRequest);

            if (accessTokenResponse && accessTokenResponse.accessToken) {
                log.trace("AuthService\nlogin()\naccessTokenResponse:", accessTokenResponse);
                return true;
            }
            
            return false;
        } catch (error) {
            log.error("AuthService\nlogin()\nerror:", error);
            return false;
        }
    }

    async logout() {
        log.debug("AuthService\nlogout()");
        try {
            await this.app.logoutPopup();
        } catch (error) {
            log.error("AuthService\nlogout()\nerror:", error);
        }
    }

    async getApiToken(loginHint) {
        log.trace("AuthService\ngetApiToken()");
        
        const activeAccount = this.app.getActiveAccount() || this.app.getAllAccounts()[0];
        if (!activeAccount) {
            log.error("No active account found.");
            return undefined;
        }
    
        try {
            const tokenResponse = await this.app.acquireTokenSilent(this.accessTokenRequest);
            return tokenResponse.accessToken;
        } catch (error) {
            log.error("Silent token acquisition failed:", error);
    
            if (error instanceof InteractionRequiredAuthError) {
                try {
                    this.authInProgress = true;
                    let popUpScope = {
                        ...this.accessTokenRequest,
                        scopes: [...this.accessTokenRequest.scopes, "openid", "profile"]
                    };
                    const tokenResponse = await this.app.acquireTokenPopup(popUpScope);
                    return tokenResponse.accessToken;
                } catch (popupError) {
                    if (popupError.name === "ClientAuthError") {
                        const maxAttempts = 500;
                        let attempt = 0;
    
                        while (this.authInProgress && attempt < maxAttempts) {
                            await new Promise(resolve => setTimeout(resolve, 15000));
                            attempt++;
                        }
    
                        if (!this.authInProgress) {
                            try {
                                const retryTokenResponse = await this.app.acquireTokenSilent(this.accessTokenRequest);
                                if (retryTokenResponse) {
                                    return retryTokenResponse.accessToken;
                                }
                            } catch (retryError) {
                                log.error("Silent token acquisition failed after waiting", retryError);
                            }
                        }
                    }
                    log.error("Token acquisition via popup failed:", popupError);
                }
            }else {
            log.error(error)
            }
        }finally {
        this.authInProgress = false;
        }
        return undefined;
    }
        
    async isLoggedIn() {
        log.trace("AuthService\nisLoggedIn()");
        const accounts = this.app.getAllAccounts();
        if (accounts.length > 0) {
            try {
                const response = await this.app.acquireTokenSilent(this.accessTokenRequest);
                if (response && response.accessToken) {
                    return true;
                }
            } catch (error) {
                log.error("AuthService\nisLoggedIn()\nerror:", error);
            }
        }
        return false
    }

    getUserName() {
        log.trace("AuthService\ngetUserName()");
        const accounts = this.app.getAllAccounts();
        if (accounts.length > 0) {
            return accounts[0].name || "Unknown User";
        }
        return "Unknown User";
    }

    getEmailAddress() {
        log.trace("AuthService\ngetEmailAddress()");
        const accounts = this.app.getAllAccounts();
        if (accounts.length > 0) {
            return accounts[0].username || "Unknown User";
        }
        log.warn("AuthService\ngetEmailAddress()\nUser email could not be retrieved.");
        return "Unknown User";
    }

    getUsersRole() {
        log.trace("AuthService\ngetUsersRole()");
        const accounts = this.app.getAllAccounts();
        if (accounts.length > 0 && accounts[0].idTokenClaims) {
            const roles = accounts[0].idTokenClaims.roles;
            if (roles && roles.length > 0) {
                return roles[0];
            }
        }
        return "UnknownRole";
    }
}