<template>
    <Popup
        :model-value=!!selectedRow
        title="Payment confirmation"
        hasClose
        @update:model-value=closeChild
    >
        <template v-if=selectedRow #contents>
            <div class="grid-item">
                The total amount to pay on this recharge invoice is:
                <strong>{{ MarketCurrency[selectedRow.market] }} {{ MonetaryValueWithCommas(selectedRow.balance) }}</strong>
            </div>

            <form class="top-content" @submit.prevent=ConfirmLedgerTransfer>
                <span style="padding-bottom: 0.4rem"
                    >Select a payment date:</span
                >
                <span
                    title="Select a date for confirmed ledger tranfers"
                    class="search-with-button"
                >
                    <div class="date-picker" style="width: 50%">
                        <DatePicker
                            v-model="selectedDate"
                            type="date"
                            :enable-time-picker="false"
                            placeholder="Select Date..."
                            :text-input-options="textInputOptions"
                            text-input
                            :max-date="new Date()"
                            :teleport="true"
                            required
                        />
                    </div>
                    <button :disabled=working class="right-button">Confirm</button>
                </span>
            </form>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits, ref } from "vue";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import RechargeInvoicePaymentItem from "@/Interfaces/Payments/RechargeInvoicePaymentItem";
import Popup from "./Popup.vue";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import { confirmRechargeInvoicePayment } from "@/services/network/index"
import IConfirmRechargeInvoicePaymentRequest from "@/Interfaces/Payments/IConfirmRechargeInvoicePaymentRequest";
import { authService } from "@/auth/authSingleton";
import DateIgnoreTime from "@/types/DateIgnoreTime";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import { MarketCurrency, MonetaryValueWithCommas } from '@/services/helpers/Helper';

const props = defineProps<{
    modelValue?: RechargeInvoicePaymentItem;
}>();
const selectedRow = computed({
    get() {
        return props.modelValue
    },
    set(val) {
        emits('update:modelValue', val);
    }
});
const selectedDate = ref<Date>();
const textInputOptions = { format: "yyyy/MM/dd" };
const working = ref(false);

const emits = defineEmits<{
    (e: 'update:modelValue', val?: RechargeInvoicePaymentItem);
    (e: 'confirm');
}>();
function closeChild(){
    selectedDate.value = undefined;
    selectedRow.value = undefined;
}
async function ConfirmLedgerTransfer() {
    if (working.value) return;
    working.value = true;
    const closeSpinner = useSpinnerPopup().show();
    try{
        if (!selectedRow.value) throw new ReferenceError('selectedRow cannot be null when submitting the form');
        const payload: IConfirmRechargeInvoicePaymentRequest = Object.assign(selectedRow.value,{
            userEmail: authService.getEmailAddress(),
            balanceDateUpdated: new DateIgnoreTime(selectedDate.value),
            amountPaid: selectedRow.value.balance,
        });
        const response = await confirmRechargeInvoicePayment.put(payload);
        useNotifierWithErrFormatter().success({ msg: "Payment successfully registered." });
        closeChild();
        emits("confirm");
    }catch (error) {
        useNotifierWithErrFormatter().error({
            name: `${__filename}/${ConfirmLedgerTransfer.name}`,
            errorType: 'Confirming payment',
            error
        });
    } finally {
        closeSpinner();
        working.value = false;
    }
}
</script>

<style scoped>
.grid-item {
    padding: 10px;
}
</style>
