import { authService } from "@/auth/authSingleton";
import { createRouter, createWebHistory } from "vue-router";
import { AuthorisationLevel, GetAuthLevel } from "@/config/AuthConfig";
import { Log2API } from "@/services/Logger";
import RedirectBlank from "../views/RedirectBlank.vue";
import NotFound from "@/views/NotFound.vue";
import Login from "@/views/Login.vue";
import Admin from "@/views/Roles/Admin.vue";
import Analyst from "@/views/Roles/Analyst.vue";
import DepositManager from "@/views/Roles/DepositManager.vue";
import Operator from "@/views/Roles/Operator.vue";
import Unauthorised from "@/views/Roles/Unauthorised.vue";
import Dashboard from "@/views/Dashboard.vue";
import Journaling from "@/views/Journaling/Journaling.vue";
import JournalingReport from "@/views/Journaling/JournalingReport.vue";
import FullPagePDF from "@/views/FullPagePDF.vue";
import AlertsAndWarnings from "@/views/AlertsAndWarnings.vue";
import EoPVerifications from "@/views/EoPVerifications/EoPVerifications.vue";
import EoPVerificationReport from "@/views/EoPVerifications/EoPVerificationReport.vue";
import Invoices from "@/views/Invoices.vue";
import DataProcessing from "@/views/DataProcessing.vue";
import LoanManagement from "@/views/Unused/LoanManagement.vue";
import FundManagement from "@/views/Unused/FundManagement.vue";
// import ConsumerExtract from "@/views/Unused/ConsumerExtract.vue";
import CustomReports from "@/views/CustomReports.vue";
import CustomerAndContract from "@/views/CustomerAndContract.vue";
import ContractHireAgreement from "@/views/ContractHireAgreement.vue";
import SageBackup from "@/views/SageBackup.vue";
import FeesConfiguration from "@/views/FeesConfiguration.vue";
import VATReturns from "@/views/VATReturns.vue";
import TransferPayments from "@/views/TransferPayments.vue";
import ServiceInvoicePayments from "@/views/Payments/ServiceInvoicePayments.vue";
import RechargeInvoicePayments from "@/views/Payments/RechargeInvoicePayments.vue";
import ChurnPayments from "@/views/Payments/ChurnPayments.vue";
import VATPayments from "@/views/VATPayments.vue";
import LedgerTransactions from "@/views/LedgerTransactions.vue";
import ContractValidation from "@/views/ContractValidation.vue";
import ContractValidations from "@/views/ContractValidations.vue";
import CarPurchases from "@/views/CarPurchases/CarPurchases.vue";
import CarPurchase from "@/views/CarPurchases/CarPurchase.vue";
import ContractApproval from "@/views/ContractApproval.vue";
import FundingReports from "@/views/FundingReports.vue";
import InsuranceClaims from "@/views/InsuranceClaims.vue"
import { getKeyByValue } from "@/services/helpers/Helper";
import { RoutePaths, RouteRecordCarsly } from "@/router/RouteDetails";
import log from "loglevel";
import Drawdown from '@/views/Drawdown/Drawdown.vue';
import VatLoans from "@/views/Drawdown/VatLoans.vue";
import ConfirmInterestPayments from "@/views/ConfirmInterestPayments.vue";
import FinalSettlementPayments from "@/views/Payments/FinalSettlementPayments.vue";

const Logger = new Log2API();
const fileName = "index.ts";
const name = "router";
const Class = fileName + "/" + name;

const dontLogRoutes: string[] = ["Page Not Found", "Login", "Unauthorised"];
const logPathNotNameRoutes: string[] = ["Journaling Report", "EoP Verification Report"];

export const routes: Array<RouteRecordCarsly> = [
    // Names should be the names we want to appear as headings
    {
        path: RoutePaths.Root,
        name: 'Root',
        redirect: {
            path: RoutePaths.Home,
        }
    },
    {
        path: RoutePaths.Blank,
        name: 'Blank',
        component: RedirectBlank,
        meta: { authLevel: AuthorisationLevel.NoAuthorisation },
    },
    {
        path: RoutePaths.Login,
        name: 'Login',
        component: Login,
        meta: { authLevel: AuthorisationLevel.NoAuthorisation },
    },
    {
        path: RoutePaths.Unauthorised,
        name: 'Unauthorised',
        component: Unauthorised,
        meta: { authLevel: AuthorisationLevel.NoAuthorisation },
    },
    {
        path: RoutePaths.Admin,
        name: "Admin",
        component: Admin,
        meta: { authLevel: AuthorisationLevel.Admin },
    },
    {
        path: RoutePaths.Analyst,
        name: "Analyst",
        component: Analyst,
        meta: { authLevel: AuthorisationLevel.Analyst },
    },
    {
        path: RoutePaths['Deposit Manager'],
        name: "Deposit Manager",
        component: DepositManager,
        meta: { authLevel: AuthorisationLevel.DepositManager },
    },
    {
        path: RoutePaths.Operator,
        name: "Operator",
        component: Operator,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths.Home,
        name: 'Home',
        component: Dashboard,
        meta: { authLevel: AuthorisationLevel.External, name: "Home" },
    },
    {
        path: RoutePaths.Journaling,
        name: 'Journaling',
        component: Journaling,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Journaling Report"],
        name: 'Journaling Report',
        component: JournalingReport,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Journaling Report (PDF)"],
        name: 'Journaling Report (PDF)',
        component: FullPagePDF,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Alerts and Warnings"],
        name: 'Alerts and Warnings',
        component: AlertsAndWarnings,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["EoP Verifications"],
        name: 'EoP Verifications',
        component: EoPVerifications,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["EoP Verification Report"],
        name: 'EoP Verification Report',
        component: EoPVerificationReport,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["EoP Verification (PDF)"],
        name: 'EoP Verification (PDF)',
        component: FullPagePDF,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Invoices"],
        name: 'Invoices',
        component: Invoices,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Invoice (PDF)"],
        name: 'Invoice (PDF)',
        component: FullPagePDF,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Data Processing"],
        name: 'Data Processing',
        component: DataProcessing,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Loan Management"],
        name: 'Loan Management',
        component: LoanManagement,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Fund Management"],
        name: 'Fund Management',
        component: FundManagement,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Consumer Extract"],
        name: 'Consumer Extract',
        // component: ConsumerExtract,
        component: Unauthorised,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Reports"],
        name: 'Reports',
        component: CustomReports,
        meta: { authLevel: AuthorisationLevel.Restricted },
    },
    {
        path: RoutePaths["Customer & Contract"],
        name: 'Customer & Contract',
        component: CustomerAndContract,
        meta: { authLevel: AuthorisationLevel.Restricted },
    },
    {
        path: RoutePaths["Hire Agreements"],
        name: 'Hire Agreements',
        component: ContractHireAgreement,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Hire Agreement (PDF)"],
        name: 'Hire Agreement (PDF)',
        component: FullPagePDF,
        meta: { authLevel: GetAuthLevel(authService.getUsersRole()) === AuthorisationLevel.External ? AuthorisationLevel.External : AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Contract Validation"],
        component: ContractValidations,
        children: [{ path: '', name: 'Contract Validation', component: ContractValidation, meta: { authLevel: AuthorisationLevel.External } }],
    },
    {
        path: RoutePaths['Contract Approval'],
        name: 'Contract Approval',
        component: ContractApproval,
        meta: { authLevel: AuthorisationLevel.External },
    },
    {
        path: RoutePaths["Car Purchases"],
        name: 'Car Purchases',
        component: CarPurchases,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Car Purchase"],
        name: 'Car Purchase',
        component: CarPurchase,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Sage Backup"],
        name: 'Sage Backup',
        component: SageBackup,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Fees Configuration"],
        name: 'Fees Configuration',
        component: FeesConfiguration,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["VAT Returns"],
        name: 'VAT Returns',
        component: VATReturns,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Transfer Payments"],
        name: 'Transfer Payments',
        component: TransferPayments,
		meta: { authLevel: GetAuthLevel(authService.getUsersRole()) === AuthorisationLevel.DepositManager ? AuthorisationLevel.DepositManager : AuthorisationLevel.Analyst },
    },
    {
        path: RoutePaths["Service Invoice Payments"],
        name: "Service Invoice Payments",
        component: ServiceInvoicePayments,
        meta: { authLevel: AuthorisationLevel.Analyst },
    },
    {
        path: RoutePaths["Recharge Invoice Payments"],
        name: "Recharge Invoice Payments",
        component: RechargeInvoicePayments,
        meta: { authLevel: AuthorisationLevel.Analyst },
    },
    {
        path: RoutePaths["Final Settlement Payments"],
        name: "Final Settlement Payments",
        component: FinalSettlementPayments,
        meta: {authLevel: AuthorisationLevel.Analyst }
    },
    {
        path: RoutePaths["Churn Payments"],
        name: "Churn Payments",
        component: ChurnPayments,
        meta: { authLevel: AuthorisationLevel.Analyst },
    },
    {
        path: RoutePaths["VAT Payments"],
        name: 'VAT Payments',
        component: VATPayments,
        meta: { authLevel: AuthorisationLevel.Analyst },
    },
    {
        path: RoutePaths["Ledger Transactions"],
        name: 'Ledger Transactions',
        component: LedgerTransactions,
        meta: { authLevel: AuthorisationLevel.Admin },
    },
    {
        path: RoutePaths["Confirm Interest Payments"],
        name: 'Confirm Interest Payments',
        component: ConfirmInterestPayments,
        meta: { authLevel: AuthorisationLevel.Operator },
    },
    {
        path: RoutePaths["Page Not Found"],
        name: 'Page Not Found',
        component: NotFound,
        meta: { authLevel: AuthorisationLevel.NoAuthorisation },
    },
    {
        path: RoutePaths["Funding Reports"],
        name: "Funding Reports",
        component: FundingReports,
        meta: { authLevel: AuthorisationLevel.Admin },
    },
    {
        path: RoutePaths["Insurance Claims"],
        name: "Insurance Claims",
        component: InsuranceClaims,
        meta: {authLevel: AuthorisationLevel.Operator}
        
    },
    {
        path: RoutePaths["Drawdown"],
        name: "Drawdown",
        component: Drawdown,
        meta: { authLevel: AuthorisationLevel.Analyst },
    },
    {
        path: RoutePaths["VAT Loans"],
        name: 'VAT Loans',
        component: VatLoans,
        meta: { authLevel: AuthorisationLevel.Analyst },
    },
    {
        path: RoutePaths['Portfolio Forecasts'],
        name: "Portfolio Forecasts",
        component: ()=>import('../views/Portfolio/PortfolioForecasts.vue'),
        meta: { authLevel: AuthorisationLevel.Restricted },
        redirect: RoutePaths['Portfolio Forecasts'] + "/" + RoutePaths['Current Portfolio'],
        children: [
            {
                path: RoutePaths['Current Portfolio'],
                name: 'Current Portfolio',
                meta: { authLevel: AuthorisationLevel.Restricted },
                component: () => import('../views/Portfolio/CurrentPortfolio.vue'),
            }, {
                path: RoutePaths['Current Portfolio Forecast Details'],
                name: 'Current Portfolio Forecast Details',
                meta: { authLevel: AuthorisationLevel.Restricted },
                component: () => import('../views/Portfolio/CurPortfolioForecastDetails.vue'),
            }, {
                path: RoutePaths['Estimated Portfolio'],
                name: 'Estimated Portfolio',
                meta: { authLevel: AuthorisationLevel.Restricted },
                component: () => import('../views/Portfolio/EstimatedPortfolio.vue'),
            }, {
                name: 'Estimated Portfolio Forecast Details',
                path: RoutePaths['Estimated Portfolio Forecast Details'],
                meta: { authLevel: AuthorisationLevel.Restricted },
                component: () => import('../views/Portfolio/EstPortfolioForecastDetails.vue'),
            }, {
                name: 'Configurations',
                path: RoutePaths['Configurations'],
                meta: { authLevel: AuthorisationLevel.Restricted },
                component: () => import('../views/Portfolio/ForecastConfigs.vue'),
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;

router.beforeEach(async (to, from) => {
    var routeNameOrPath = to.name ?? to.path;

    // Logging
    const Message =
        `Tried to access ${String(routeNameOrPath)}, requiring access level: ${getKeyByValue(AuthorisationLevel, to.meta.authLevel)}`;
    // ==============
    // Redirect logic
    // ==============
    if (to.name == 'Login' && await authService.isLoggedIn()) {
        return { path: to.query.redirect ?? RoutePaths.Home };
    }
    else if (to.name == 'Root' && await authService.isLoggedIn()) {
        return { path: RoutePaths.Home };
    }

    if (to.name === 'Car Purchases' && from.name === 'Car Purchase') {
        const marketParam = from.params.market || 'GB';  
        if (to.params.market !== marketParam) {
            return ({ name: 'Car Purchases', params: { market: marketParam }});
        }
    }
    
    // NOT LOGGED IN LOGIC
    if (!await authService.isLoggedIn()) {

        // Redirect to "Login" if accessing authorised pages
        if (to.meta.authLevel != AuthorisationLevel.NoAuthorisation) {
            log.warn(
                "Accessing restricted content whilst not logged in. Redirecting to login.",
            );
            return {
                path: RoutePaths.Login,
                query: { redirect: to.fullPath },
            };
        }

        // Redirect to "Login" and then "Home" if accessing the "Unauthorised" page
        if (to.name == 'Unauthorised') {
            return {
                path: RoutePaths.Login,
                query: { redirect: RoutePaths.Home },
            };
        }
    }

    // LOGGED IN LOGIC
    else {
        var logged: boolean = false;

        // Redirect to "Not Found" if invalid route
        // const exists = routes.find((destination) => destination.name === to.name)
        // if (!exists) {
        //     const Message = `Attempted to access page: ${String(to.path)}`
        //     const Class = "router";
        //     Logger.LogInformation(Message, Class);
        //     logged = true;
        //     return { name: "Page Not Found" }
        // }

        // Redirect to "Unauthorised" if unauthorised
        if (
            GetAuthLevel(await authService.getUsersRole()) <
            Number(to.meta.authLevel) &&
            authService.isLoggedIn()
        ) {
            // Log Information if user is accessing restricted content
            if (!logged) {
                Logger.LogInformation(Message + " (ACCESS DENIED)", Class);
                logged = true;
            }
            return {
                path: RoutePaths.Unauthorised,
                query: { redirect: to.fullPath },
            };
        }

        // Log unhandled case
        else {
            Logger.LogInformation(`To: ${String(to.name)}, From: ${String(from.name)}`);
            if (!logged && !dontLogRoutes.includes(to.name!.toString())) Logger.LogInformation(Message, Class);
        }
    }
});
