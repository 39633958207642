<template class="FinalSettlementPayments">
    <div v-if="isLoading" class="content-container">
        <div class="loading">
            <span class="loader"></span>
        </div>
    </div>

    <div v-else class="content-container">
        <div class="top-content">
            <GettingFinalSettlementErrorInfo
                :showError="!!infoError"
                errorDescription="(Getting Final Settlements)"
                :error="infoError"
                :change="!!infoError"
            />
            <div class="flex-row-centered">
                <MarketSelector @change="getPayments" enableSE />
                <div class="center-buttons">
                    <button
                        @click="proceedToPayment"
                        class="incident-nav-button"
                    >
                        Confirm Payment
                    </button>
                </div>
            </div>
        </div>
        <div class="table-container">
            <TablePersistentChecking
                ref="table"
                :Rows="displayedPayments"
                :Cols="cols"
                :Labels="labels"
                :HasCheckBox="true"
                :SingleSelection="true"
            />
        </div>

        <FinalSettlementPaymentPopUp
            v-model="selectedRow"
            @confirm="refreshAfterConfirm"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, Ref, onMounted, computed, provide } from "vue";
import { marketSelection } from "@/services/helpers/Helper";
import TablePersistentChecking, {
    ITableExpose,
} from "@/components/TablePersistentChecking.vue";
import PaymentItem from "@/Interfaces/Payments/FinalSettlementPaymentItem";
import FinalSettlementPaymentPopUp from "@/components/FinalSettlementPaymentPopUp.vue";
import ErrorInfo from "@/components/ErrorInfo.vue";
import { finalSettlementPayments } from "@/services/network";
import MarketSelector from "@/components/MarketSelector.vue";
import useNotifier from "@/services/composables/Notifier";

const cols: (keyof PaymentItem)[] = [
    "invoiceId",
    "balance",
    "amountTotal",
    "issueDate",
    "dueDate",
    "balanceDateUpdated",
];
const labels = [
    "Invoice Id",
    "Balance",
    "Amount Total",
    "Issue Date",
    "Due Date",
    "Balance Date Updated",
];

const page: Ref<number> = ref(1);
const rowCount: Ref<number> = ref(50);
provide("page", page);
provide("rowCount", rowCount);

// ERRORS
const GettingFinalSettlementErrorInfo = ErrorInfo;
const infoError = ref();

// Table
const isLoading = ref(false);
const allPayments = ref<PaymentItem[]>([]);

const displayedPayments = computed(() =>
    allPayments.value
);
const checkedRow = ref<PaymentItem>();
const selectedRow = ref<PaymentItem>();
const table = ref<ITableExpose<PaymentItem>>();
    

onMounted(async () => {
    marketSelection.value = "GB";
    getPayments();
});
async function getPayments() {
    //const errorType = 'Getting Final Settlement Invoice Payments from API';
    try {
        isLoading.value = true;
        allPayments.value = (await finalSettlementPayments.get({market: marketSelection.value})).data.map(
            (x) => new PaymentItem(x)
        );
        updateSelection();
    } catch (error) {
        infoError.value = error;
    } finally {
        isLoading.value = false;
    }
}
const updateSelection = () => {
    checkedRow.value = undefined;
    selectedRow.value = undefined;
};
const proceedToPayment = () => {
    if (checked.value.length < 1) useNotifier().notify({ msg: "No item selected" });
    else {
        selectedRow.value = checked.value[0];
    }
};
const checked: Ref<PaymentItem[]> = computed(() => {
    return table.value?.GetChecked() ? table.value?.GetChecked() : [];
});
function refreshAfterConfirm() {
    getPayments();
    checkedRow.value = undefined;
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/layout.scss";
@import "@/assets/styles/buttons.scss";
@import "@/assets/styles/table.scss";
.vtl-thead {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    text-align: left;
    background: var(--primary-color-dark);
    /* color: #fff; */
    color: red;
}
.incident-nav-button {
    margin-bottom: 0px
}
.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
}
.utility-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.1rem;
}
.utility-bar > .link-btn {
    margin-left: auto;
}
.link-btn > a {
    cursor: pointer;
    color: var(--theme-link-color);
    &:hover {
        text-decoration: underline;
        color: var(--theme-link-color-hover);
    }
}
</style>